*, html, head, body {
  padding: 0px;
  margin: 0px;
}

#root {
  --user-circle-color: #d40000;
  --shadow-color: #acacac;
  --button-color: #ad0e0e;
  --menu-items-color: #1c1c1d;
  overflow-x: hidden;
}

.container {
  padding: 0px;
  margin: 0px;
  position: relative;
  top: 0%;
  left: 0%;
  display: flex;
  flex-direction: column;
}

#root {
  position: relative;
}

.sidebar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  box-shadow: 3px 3px 5px var(--shadow-color);
  border-bottom: 1px solid #fff;
  width: calc(100vw - 20px);
  position: fixed;
  top: 0%;
  left: 0%;
  background-color: #fff !important;
}

.company {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.company .logo{
  width: 220px;
  height: 50px;
  border: 1px solid #fff;
}

.company .logo img{
  width: 220px;
  height: 50px;
  border: 1px solid #fff;
}

.menu-items {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: auto;
  min-height: 79.5vh;
  padding: 30px 50px 0px 25px;
  border-right: 1px solid var(--shadow-color);
  box-shadow: 3px 3px 3px var(--shadow-color);
  background-color: var(--menu-items-color);
  color: #fff;
}

.menu-items-login, .to-menus {
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: 50px;
  align-items: center;
  margin-right: 20px;
}

.menu-items a,
.menu-items .active,
.menu-items-login a,
.menu-items-login .active ,
.to-menus a,
.to-menus .active  {
  color: #3e3e3e;
  text-decoration: none;
  font-weight:normal;
  font-size: 20px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.menu-items a,
.menu-items .active {
  color: #fff;
}

.menu-items a:hover{
  color: #fff;
  font-size: 22px;
}

.menu-items-login a:hover,
.to-menus a:hover{
  color: #000;
  font-size: 22px;
}

.signup, .login {
  width: 400px;
  margin: 10px auto;
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
  border: 1px solid #cecece;
  border-radius: 50px;
  box-shadow: 3px 3px 3px var(--shadow-color);
}

.main-container {
  margin-top: 11vh;
  display: flex;
  flex-direction: row;
}

.output {
  flex: 1;
  margin-top: 10px;
}

.signup table {
  margin: -10px;
}

.signup table thead tr,
.orders .order-table table thead tr {
  color: #fff;
  background-color: var(--button-color);
  border-bottom: 2px solid #000;
}

.signup table thead tr th:first-child,
.orders .order-table table thead tr th:first-child{
  border-top-left-radius: 20px;
}

.signup table thead tr th:last-child,
.orders .order-table table thead tr th:last-child{
  border-top-right-radius: 20px;
}

.signup table tbody tr:last-child td:first-child,
.orders .order-table table tbody tr:last-child td:first-child{
  border-bottom-left-radius: 20px;
}

.signup table tbody tr:last-child td:last-child,
.orders .order-table table tbody tr:last-child td:last-child{
  border-bottom-right-radius: 20px;
}

.signup table thead tr th,
.orders .order-table table thead tr th {
  padding: 10px !important;
  text-align: center;
  width: max-content;
}

.signup table tbody tbody tr:nth-child(2n),
.orders .order-table table tbody tr:nth-child(2n){
  background-color: #bebebe !important;
  color: #000 !important;
  margin: 0px;
}

.signup table tbody tbody tr,
.orders .order-table table tbody tr {
  background-color: #dfdfdf !important;
  color: #000 !important;
  margin: 0px;
}

.signup table tbody tbody tr:nth-child(2n) td a,
.orders .order-table table tbody tr:nth-child(2n) td a{
  color: #000 !important;
}

.signup table tbody tbody tr td a,
.orders .order-table table tbody tr td a{
  color: #000 !important;
}

.signup table tbody tr td,
.orders .order-table table tr td,
.signup table tbody tr th,
.orders .order-table table tr th{
  font-size: 12px;
}

.signup table tbody tr td,
.orders .order-table table tr td{
  padding: 10px !important;
  text-align: center;
  width: max-content;
  max-width: 200px;
  word-wrap: break-word;
}


.inputs {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.inputs .cont {
  display: flex;
  flex-direction: row;
}

.inputs input,
.inputs .cont input{
  width: 88%;
  margin: 10px 5px;
  padding: 5px 12px;
  border: 2px solid #fff;
  box-shadow: 3px 3px 3px var(--shadow-color);
  border-radius: 10px;
  font-size: 18px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  outline: none;
}

.inputs .cont input{
  flex: 1;
}

.inputs .cont div{
  align-self: center;
  cursor: pointer;
}

.inputs .cont div svg{
  font-size: 25px;
  align-self: center;
  color: var(--shadow-color);
}

.inputs select {
  padding: 5px 8px;
  margin: 5px 8px;
  border: 1px solid #fff;
  border-radius: 8px;
  font-size: 18px;
  color: #6d6d6d;
  box-shadow: 3px 3px 3px var(--shadow-color);
  cursor: pointer;
  width: 94%;
}

.inputs textarea {
  max-width: 365px;
  max-height: 70px;
  min-width: 365px;
  min-height: 70px;
  padding: 5px 8px;
  margin: 5px 8px;
  border: 1px solid #fff;
  border-radius: 5px;
  font-size: 18px;
  color: #6d6d6d;
  box-shadow: 3px 3px 3px var(--shadow-color);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  outline: none;
}

.inputs input:focus,
.inputs select:focus,
.inputs textarea:focus {
  border: 2px solid var(--shadow-color);
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
}

.buttons button{
  flex: 1;
  background-color: var(--button-color);
  color: #fff;
  padding: 8px 0px;
  margin: 10px 5px;
  font-size: 15px;
  font-weight: bold;
  border: 1px solid var(--button-color);
  border-radius: 5px;
  cursor: pointer;
}

.buttons button:hover{
  box-shadow: 5px 5px 5px var(--shadow-color);
}

.heading {
  text-align: center;
  justify-content: center;
  margin: 10px 0px;
}

.heading h1 {
  font-size: 25px;
  font-weight: bold;
}

td button {
  background-color: var(--button-color);
  color: #fff;
  padding: 5px 8px;
  font-size: 15px;
  font-weight: bold;
  border: 1px solid var(--button-color);
  border-radius: 5px;
  cursor: pointer;
}

td a{
  text-decoration: none;
  outline: none;
}

td a:hover{
  text-decoration: underline;
}

td button:hover {
  box-shadow: 3px 3px 3px #acacac;
}

.opacity-80 {
  opacity: 80%;
}

.animate-spin{
  animation-name: spin;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.skeleton-box {
  position: relative;
  height: 30px;
  background-color: rgb(230, 230, 230);
  overflow: hidden;
  margin-bottom: 10px;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 30px;
    background-image: linear-gradient(90deg, rgb(230, 230, 230), rgb(255, 255, 255), rgb(230, 230, 230));
    z-index: 999;
    left: 0px;
    animation: skeleton 1s linear infinite;
    transform: skew(0deg);
  }
}

@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}

@keyframes skeleton{
  0% {
    left: 0%;
  }
  100% {
    left: 100%;
  }
}

.error{
  text-align: center;
  margin: 3px 0px;
  color: rgb(216, 43, 43);
  font-size: 15px;
}

.user {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  margin: 0px 10px;
}

.user-circle{
  font-size: 30px;
  background-color: var(--user-circle-color);
  color: #fff;
  text-align: center;
  height: 35px;
  width: 35px;
  border: 1px solid var(--user-circle-color);
  border-radius: 50%;
}

.user-name{
  font-size: 30px;
}

.profile-menu{
  background-color: #fff;
  color: #000;
  display: none;
  flex-direction: column;
  position: absolute;
  top: 55px;
  right: 40px;
  border: 1px solid #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 5px 5px 5px var(--shadow-color);
}

.profile-menu div{
  padding: 15px 30px;
  margin: auto;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.profile-menu div a{
  text-decoration: none;
  color: #000;
}

.profile-menu div:hover{
  font-size: 22px;
}

.forgot {
  align-self: center;
  text-align: center;
}

.forgot a{
  text-decoration: none;
  color: var(--shadow-color);
}

.forgot a:hover{
  color: #000;
}

.hold-container {
  display: flex;
  flex-direction: row;
  margin: 0px 50px;
  align-items: center;
  flex-wrap: wrap;
}

.orders {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.orders .top{
  margin: 10px 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
}

.orders .top button, .recharge, .recharge-modal button {
  background-color: #ad0e0e;
  color: #fff;
  padding: 8px 12px;
  font-size: 15px;
  font-weight: bold;
  border: 1px solid #ad0e0e;
  border-radius: 5px;
  cursor: pointer;
}

.orders .top button:hover, .recharge:hover,  .recharge-modal button:hover {
  box-shadow: 3px 3px 3px #acacac;
}

.orders .top input {
  width: 500px;
  font-size: 18px;
  padding: 8px 18px;
  border: 1px solid #fff;
  border-radius: 20px;
  box-shadow: 3px 3px 3px var(--shadow-color);
  transition: all 0.3s ease-in-out;
  outline: none;
}

.orders .top input:focus {
  border-color: var(--shadow-color);
}

.orders .order-table{
  flex: 1;
  padding: 20px 30px;
  margin: 10px 20px;
  border: 1px solid #cecece;
  border-radius: 30px;
  box-shadow: 5px 5px 5px var(--shadow-color);
}

.orders .order-table table {
  width: 100%;
}

.orders .order-table .no-ord{
  width: 100%;
  text-align: center;
  color: var(--shadow-color);
  padding: 30px 0px;
  font-size: 22px;
}

.create-order {
  display: flex;
  flex-direction: row;
  gap: 30px;
  flex-wrap: wrap;
  margin: auto;
  width: max-content;
  max-width: 70vw;
}

.create-order .signup {
  width: 350px !important;
  border-radius: 50px;
  padding: 30px 50px;
  box-shadow: 5px 5px 5px var(--shadow-color);
  height: fit-content;
  margin: 0px auto;
}

.create-order .signup table {
  margin: -15px -35px;
}

.wallet{
  font-size: 18px;
  padding: 3px 12px;
  border: 1px solid var(--shadow-color);
  border-radius: 20px;
}

.wallet img {
  margin: 0px 5px;
  margin-bottom: -5px;
  width: 20px;
}

.recharge-modal {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.recharge-modal .heading {
  font-size: 22px;
  text-align: center;
  margin: 0px;
  padding: 0px;
}

.recharge-modal input {
  width: 300px;
  padding: 5px 18px;
  border: 1px solid #fff;
  border-radius: 20px;
  font-size: 22px;
  box-shadow: 3px 3px 3px #acacac;
  transition: all 0.3s ease-in-out;
  outline: none;
}

.recharge-modal input:focus {
  border: 1px solid #acacac;
}

.click {
  padding: 5px 8px;
  border: 1px solid #acacac;
  border-radius: 15px;
  cursor: pointer;
}

.skel {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-self: center;
  margin: 150px auto;
  width: fit-content;
  align-items: center;
  color: #3e3e3e;
}

.skel .spinner {
  display: flex;
  justify-content: center;
  font-size: 30px;
}

.single {
  margin: 50px;
}

.single .details {
  margin: 20px 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.single .details div {
  max-width: 30vw;
}

.single .desc {
  text-align: center;
  margin: 30px 10px;
  color: #3e3e3e;
  font-size: 20px;
}

.single .reply {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.reply .heading{
  font-weight: bold;
  font-size: 22px;
  text-align: center;
}

.reply textarea {
  flex: 1;
  max-height: calc(100%-20px);
  min-width: calc(100%-20px);
  max-height: 70px;
  min-height: 70px;
  margin: 10px;
  padding: 10px;
  color: #000;
  border: 1px solid #fff;
  border-radius: 10px;
  box-shadow: 3px 3px 3px #acacac;
  transition: all 0.3s ease-in-out;
  outline: none;
}

.reply textarea:focus {
  border-color: #acacac;
}

.reply .buttons {
  align-self: flex-end;
  width: 500px;
  justify-content: space-between;
}

.order-details .details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 600px;
  margin: 20px auto;
}

.order-details .details-single{
  width: fit-content;
  margin: 20px auto;
}

.order-details .details .status,
.order-details .details-single .status {
  padding: 5px 12px;
  background-color: var(--button-color);
  color: #fff;
  font-size: 22px;
  border-radius: 10px;
  justify-content: center;
  align-self: center;
}

.order-details .details .message {
  font-size: 20px;
  font-weight: 600;
  padding: 5px 0px;
}

.order-table .buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  float:right;
  width: fit-content;
}

.order-table .buttons button {
  padding: 8px 12px;
  font-size: 18px;
}

.label {
  margin: 10px;
  border: 1px solid #383838;
}

.label .details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 50px;
  border-bottom: 1px solid #383838;
}

.label .details div {
  font-size: 18px;
  font-weight: 600;
  max-width: 45vw;
  line-height: 30px;
}

.label .details div img {
  width: 30vw;
}

.label .details .barcode{
  align-items: center;
  font-size: 30px;
}

.label .pay-details {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 10px;
  margin: 0px 50px;
  padding-top: 0px;
  border-bottom: 1px solid #000;
}

.label .pay-details div {
  font-size: 25px;
  font-weight: 600;
}

.skeleton {
  width: 155px;
  margin: 5px;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  position: relative;
  overflow: hidden;
  &::before {
      content: '';
      position: absolute;
      width: 1%;
      height: 100%;
      background-image: linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255), rgb(255, 255, 255));
      z-index: 999;
      left: 0px;
      animation: skeleton 1s linear infinite;
      transform: skew(-30deg);
  }
}

@keyframes skeleton{
    0% {
        left: 0%;
    }
    100% {
        left: 100%;
    }
}

.skeleton .user-circle {
  height: 35px;
  width: 35px;
  background-color: #acacac;
  border-color: #acacac;
}

.skeleton .user-name {
  height: 35px;
  width: 100px;
  border-radius: 35px;
  background-color: #acacac;
}

.track {
  margin: 30px auto;
}

.track .top{
  justify-content: center !important;
}

.dashboard {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.dashboard .top {
  display: flex;
  flex-direction: row;
  gap: 50px;
  justify-content: center;
  margin: auto;
}

.dashboard .top div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dashboard .top .left div {
  display: flex;
  flex-direction: row;
  gap: 30px;
  padding: 20px;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 20px;
  box-shadow: 3px 3px 3px #acacac;
}

.dashboard .top .left h1,
.dashboard .top .right div .top h1 {
  color: #1c1c1d;
  font-size: 18px;
  width: 150px;
}

.dashboard .top .left p,
.dashboard .top .right div .top p {
  font-weight: bolder;
  font-size: 80px;
  color: #424242;
}

.dashboard .top .right {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.dashboard .top .right div {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 3px 3px 3px #acacac;
}

.dashboard .top .right div .top {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  box-shadow: none;
}

.dashboard .top .right div .bar {
  height: 200px;
  width: 500px;
  box-shadow: none;
}

.dashboard .top .right .welcome {
  padding: 10px;
}

.dashboard .top .right .welcome .top h1 {
  width: fit-content;
}

.dashboard .top .right .welcome .bar {
  width: 250px;
}

.carousel {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: -5px;
  overflow: hidden;
  width: 100vw;
  position: relative;
  transition: all 0.2s ease-in-out;
}

.carousel div{
  position: relative;
}

.carousel div,
.carousel div img {
  width: 100vw;
  height: 90vh;
}

.carousel div h1{
  position: absolute;
  top: 5%;
  left: 5%;
  color: #fff;
  width: 500px;
  font-size: 40px;
  font-weight: bolder;
  text-shadow: 3px 3px 3px #696969;
}

.carousel div button {
  position: absolute;
  top: 77%;
  left: 5.5%;
  color: #000;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 5px 12px;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.carousel div button:hover {
  background-color: #000;
  border: 1px solid #000;
  color: #fff;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 2%;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 2%;
}

.left-arrow,
.right-arrow {
  font-size: 30px;
  color: #fff;
  font-weight: bolder;
  cursor: pointer;
}
.animate {
  animation: carousel infinite 10s;
}

.services {
  display: flex;
  justify-content: center;
  background-color: #F9F1EF;
}

.services-2 {
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  text-align: center;
  padding: 100px 20px;
}

.services-2 h1 {
  font-size: 50px;
}

.services-2 img {
  width: 500px;
  margin: auto;
}

.about {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 100px 30px;
}

.about div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.about .left {
  justify-content: flex-start;
  color: #000;
}

.about .right{
  justify-content: center;
  padding: 20px;
}

.about .left h1 {
  font-size: 40px;
}

.about .left p {
  font-size: 30px;
  max-width: 700px;
  font-style: italic;
}

.about .right div {
  width: 300px;
}

.about .right div img {
  width: 300px;
}

.partners {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 100px;
}

.partners h1{
  text-align: center;
  font-size: 30px;
}

.partners .images {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50px;
}

.partners .images img {
  width: 200px;
}

.footer {
  background-color: #1c1c1d;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 20px 50px;
  align-items: center;
}

.footer .contacts {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer .contacts h1 {
  font-size: 30px;
}

.footer .contacts p {
  font-size: 20px
}

.footer .conditions {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 10px;
}

.footer .conditions h1 {
  font-size: 30px;
}

.footer .conditions .list {
  width: 700px;
}

.link {
  transition: all 1s ease-in-out;
}

.link a img {
  color: #fff;
  margin: 0px 3px;
  margin-bottom: -5px;
  filter: grayscale(1) brightness(0) invert(1);
  max-width: 25px;
}

.sub-link {
  margin: 10px 0px;
  margin-left: 20px;
  transition: all 1s ease-in-out;
}

.reviews {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  margin: 100px 50px;
  margin-top: -70px;
}

.reviews .list {
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  margin: 0px 50px;
  max-width: 1100px;
  overflow: hidden;
}

.reviews svg {
  position: absolute;
  bottom: 470px;
  font-size: 40px;
  cursor: pointer;
}

.reviews .left {
  left: 40px;
}

.reviews .right {
  right: 40px;
}

.reviews .list .single {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  min-width: 300px;
  max-width: 300px;
  padding: 10px 20px;
  border-radius: 10px;
  margin: 10px 5px;
  box-shadow: 3px 3px 3px #acacac;
}

.reviews .list .single .heading p {
  font-size: 15px;
  font-weight: 700;
}

@keyframes carousel {
  0% {
      margin-left: 0vw;
  }
  33% {
      margin-left: -100vw;
  }
  66% {
      margin-left: -200vw;
  }
  100% {
      margin-left: 0vw;
  }
}

@media screen and (max-width: 480px) {
  .to-menus {
    position: fixed;
    bottom: 0%;
    left: 0%;
    background-color: #fff;
    width: 100vw;
    align-items: center;
    justify-content: space-evenly;
  }

  .to-menus a {
    font-size: 22px;
  }

  a {
    font-size: 8px;
  }
  .company .logo img {
    width: 120px;
    height: 30px;
  }
  .company .logo {
    width: 120px;
    display: flex;
    align-items: center;
  }
  .carousel div, .carousel div img {
    height: 40vh;
  }
  .left-arrow, .right-arrow {
    display: none;
  }
  .about {
    flex-direction: column;
    gap: 30px;
    padding: 20px;
  }
  .about .left {
    justify-content: center;
  }
  .about .left h1 {
    font-size: 25px;
    width: 83vw;
    text-align: center;
  }
  .about .left p {
    font-size: 22px;
    width: 83vw;
    padding: 10px;
    text-align: center;
  }
  .services {
    width: 100vw;
  }
  .services img {
    width: 100vw;
    height: 35vh;
  }
  .services-2 h1 {
    width: 90vw;
    font-size: 25px;
  }
  .services-2 img {
    width: 90vw;
    margin: 0px;
  }
  .partners h1 {
    width: 100vw;
    font-size: 25px;
  }
  .partners .images {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
  }
  .partners .images img {
    width: 120px;
  }
  .footer {
    margin-bottom: 50px;
    flex-direction: column;
  }
  .footer .conditions .list {
    width: 80vw;
  }
  .footer .conditions h1 {
    font-size: 25px;
  }
  .footer .contacts {
    justify-content: center;
    align-items: center;
  }
  .footer .contacts h1 {
    font-size: 25px;
  }
  .orders .top input {
    width: 52vw;
  }
  .signup, .login {
    width: 70vw;
    padding: 10px 20px;
  }
  .heading h1 {
    font-size: 20px;
  }
  .inputs input, .inputs .cont input {
    font-size: 12px;
    margin: 5px;
    padding: 2px 5px;
  }
  .inputs .cont div svg {
    font-size: 20px;
  }
  .buttons button {
    font-size: 10px;
    padding: 5px;
  }
  .carousel {
    margin-top: -15px;
  }
  .carousel div h1{
    width: 200px;
    font-size: 20px;
  }
  .carousel div button {
    font-size: 15px;
  }
  .user-name {
    display: none;
  }
  .user-circle {
    font-size: 22px;
    width: 30px;
    height: 30px;
  }
  .main-container {
    margin-top: 10vh;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 10vh;
  }
  .menu-items {
    width: 100vw;
    min-height: fit-content;
  }
  .dashboard .top {
    flex-direction: column;
    margin: 10px;
  }
  .dashboard .top .left div {
    justify-content: space-between;
    gap: none;
  }
  .dashboard .top .right div {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
  .dashboard .top .left p, .dashboard .top .right div .top p{
    font-size: 40px;
  }
  .dashboard .top .right div .bar {
    width: 220px;
    height: 220px;
  }
  .orders .order-table {
    overflow: scroll;
    display: flex;
    flex-direction: column;
  }
  .heading {
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 10px 0px;
    align-items: center;
  }
  .orders .top button, .recharge, .recharge-modal button{
    padding: 5px 5px;
    font-size: 12px;
  }
  .wallet {
    font-size: 15px;
  }
  .wallet img {
    margin: 0px 1px;
    margin-bottom: -3px;
    width: 17px;
  }
  .recharge-modal input {
    width: 150px;
  }
}